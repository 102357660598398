import { useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AccessDenied } from "../Components/AccessDenied";
import { AuthContext } from "../Components/Context";

export const PrivateRoute = ({ children, roles }) => {
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);
  const userHasRequiredRole =
    currentUser && roles.includes(currentUser.role) ? true : false;
  useEffect(() => {
    if (!currentUser) {
      return <Navigate to="/login" />;
    }
    if (!userHasRequiredRole) {
      <Navigate to="/login" />
            return;
    }
  }, []);

  if (userHasRequiredRole) return children;
};
