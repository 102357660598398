import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Components/Context";
import { Route, Routes } from "react-router";
import { Dashboard } from "./Dashbaord";
import { ManageVendors } from "./ManageVendors";
import {
  getQueryDocs,
  getSingleDoc,
  setDocumnet,
} from "../../../MiddleWare/Firebase-functions";
import {
  orderV2sCollection,
  ordersCollection,
} from "../../../Firebase/Collections";
import { FetchAPI } from "../../../Components/Utils/FetchAPI";
import { documentId } from "firebase/firestore";
import moment from "moment";
import { FetchMagentoVendors } from "../../../MiddleWare/MagneoVendors";
import { PickUpsAdmin } from "../admin";
import { API_URL } from "../../../Firebase/API_URL";
import { CallingStatus } from "../../../Data/CallingStatus";
import dayjs from "dayjs";

export const TamataTracking = () => {
  const {
    setLoading,
    currentUser,
    setMagentoVendors,
    setPendingOrders,
    pendingOrders,
  } = useContext(AuthContext);
  const fetchOrders = useCallback(async () => {
    console.log("domne");
    setLoading(true);
 
      var { data } = await FetchAPI(

        `${API_URL}/pendingOrders?skip=0&take=${90000}`,

      
        {
          method: "GET",
        }
      );

      if (!data) {
        setLoading(false);
        return;
      }
      var orderArchive = await getQueryDocs(
        orderV2sCollection,
        documentId(),
        "in",
        [
          ...data
          .filter((e)=>dayjs(new Date()).diff(new Date(e.confirmation_date),"hour")>3)
            .map((e) => e.vendor_order_with_classification)
        ]
      );
      orderArchive = orderArchive.reduce((a, v) => ({ ...a, [v.id]: v }), {});

      data = data.map((e) => ({
        in_warehouse: e.in_warehouse,
        image_url: e.product_image || e.image_url,
price:e.price,
        name: e.name,
        qty_ordered: e.qty_ordered,
        sku: e.sku,
        status: e.status,
        payment_method:e.payment_method,
        vendor_name: e.vendor_name,
        vendor_order_with_classification: e.vendor_order_with_classification,
        notes: orderArchive[e.vendor_order_with_classification]?.notes || [],
        isReplaced:e.isReplaced||false,
        isPick:
          orderArchive[e.vendor_order_with_classification]?.pickStatus || 0,
        pickBy:
          orderArchive[e.vendor_order_with_classification]?.createBy || "",
        PickAt: orderArchive[e.vendor_order_with_classification]?.PickAt || "",
        confirmation_date: moment(e.confirmation_date)
          .add(3, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
        created_at: moment(e.created_at)
          .add(3, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
        callingStatus:
          orderArchive[e.vendor_order_with_classification]?.callingStatus ||
          CallingStatus.Idle.value,
      }));
      setPendingOrders((prev) =>
        [...prev, ...data].sort(
          (a, b) => moment(a.confirmation_date) - moment(b.confirmation_date)
        )
      );
    console.log(data);
    localStorage.setItem("id", JSON.stringify(pendingOrders));
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" index={true} element={<Dashboard />} />
        <Route path="/Manage_opertaion/*" element={<PickUpsAdmin />} />
      </Routes>
    </>
  );
};
