import {useNavigate} from "react-router";
import {IconContext} from "react-icons/lib";
import {BiMap, BiPhoneCall} from "react-icons/bi";
import {useContext} from "react";
import {AuthContext} from "../../../Components/Context";
import {UserRole} from "../../../Data/UsersRole";
import {GrDeliver} from "react-icons/gr";
import {AiOutlineDeliveredProcedure} from "react-icons/ai";

export const VendorCard = ({data,index}) => {
    const {currentUser}=useContext(AuthContext)
    const navigate = useNavigate();
const handelOpenLocation=()=>{
    window.open(data.locationLink||"");

}
    return (
        <>
            <div className="vendor_card">
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"flex-start"}}>
                    <span className="id">#{index+1}</span>
                 {data.locationLink &&  <div onClick={()=>handelOpenLocation()}><BiMap size={"35px"}/></div>}  
                </div>
                <div className="details" style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                    <div>
                    <span>Vendor Name</span>
                    <span>{data.name||data.arabicName ||""}</span>
                        </div>
                    <div>
                    <span>{data.isFirstTime&&<AiOutlineDeliveredProcedure fill={"red"} size={"28px"} color={"red"} />}</span>
                        </div>
                </div>
                <div className="details">
                    <span>Location</span>
                    <span>{data.address||""}</span>
                </div>
                <div className="details" style={{position:"relative",bottom:"0%"}}>
                    <div className="vendor_actions">
                        <span>
                            <a href={`tel:${data.phone}`}>

                                <IconContext.Provider
                                    value={{
                                        color: "#000",
                                        size: "20px",
                                        style: {marginTop: "8px"},
                                    }}
                                >
                                    <BiPhoneCall />
                                </IconContext.Provider>
                            </a>
                        </span>
                        <span onClick={() => navigate(`${currentUser.role==UserRole.Agent?`OrdersPick/${data.vendorId}`:`/tamata_opration/vendorPickups/OrdersPick/${data.vendorId}`}`,{
                            state:data
                        })}>Start</span>
                    </div>
                </div>
            </div>
        </>
    )
}