export const CompleteStatus=({text})=>{
    return(
        <span className="on-time">{text||" "}</span>
    )
}
export const FailedStatus=({text})=>{
    return(
        <span className="on-late">{text||" "}</span>
    )
}
export const UpComming=({text})=>{
    return(
        <span className="status-upcoming">{text||" "}</span>
    )
}