import dayjs from "dayjs";
import {useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { CurrencyIqFormat } from "../../../Components/Input/CurrencyIqFormat ";
import ClickNHold from "react-click-n-hold";
import { ConfirmPick } from "./Modal/ConfirmPickPopup";
import Button from "../../../Components/Button/Button";
export const OrderCard = ({ data, setVendorOrders, index, HandelPickItem }) => {
  const [open, setOpen] = useState(false);
  const handelConfirmPick = () => {
    HandelPickItem(data);
    setOpen(false);
  };

  return (
    <>
      <ClickNHold
        time={0.4}
        onClickNHold={() => {
          setVendorOrders((prevState) => {
            prevState[
              prevState.findIndex(
                (c) =>
                  data.vendor_order_with_classification ==
                  c.vendor_order_with_classification
              )
            ].isSelected =
              !prevState[
                prevState.findIndex(
                  (c) =>
                    data.vendor_order_with_classification ==
                    c.vendor_order_with_classification
                )
              ].isSelected;
            return [...prevState];
          });
        }}
        key={data.id + "-" + Math.random() * 99}
      >
        <div className="card" key={index}>
          <div className="card_info">
            <div className="card_img">
              <div>
                <span style={{ fontWeight: "600" }}> # {index + 1}</span>
                <PhotoProvider
                  photoClosable={true}
                  speed={() => 200}
                  maskClosable={true}
                >
                  <PhotoView src={data.image_url || "/Images/logo_ta.png"}>
                    <img
                      src={data.image_url || "/Images/logo_ta.png"}
                      style={{ width: "100%" }}
                    />
                  </PhotoView>
                </PhotoProvider>
              </div>
            </div>
            <div className="card_details">
              <div className="field_info">
                <span className="item_name" style={{ direction: "rtl" }}>
                  {data.name || ""}
                </span>
              </div>

              <div className="field_info">
                <span className="item_name">ID : </span>
                <span>{data.vendor_order_with_classification}</span>
              </div>
              <div className="field_info">
                <span className="item_name">Price : </span>
                <span>


                  <CurrencyIqFormat val={data.price } />

                </span>
              </div>
              <div className="field_info">
                <span className="item_name">Qty : </span>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "900",
                    backgroundColor: "#EB0028",
                    color: "white",
                    width: "40px",
                    textAlign: "center",
                    marginLeft: "10px",
                  }}
                >
                  {Number(data.qty_ordered)} 
                </span>
              </div>
              <div className="field_info">
                <span className="item_name">Attribute : </span>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "900",
                    width: "40px",
                    textAlign: "center",
                    marginLeft: "10px",
                  }}
                >
                  {data.itemAttribute&&data.itemAttribute.toString()||""} 
                </span>
              </div>
              <div className="field_info">
                <span className="item_name">Date : </span>
                <span>
                  {dayjs(data.confirmation_date).format("YYYY-MM-DD")}
                </span>
              </div>
              <div className="field_info">
                <span className="item_name">Vendor : </span>
                <span>{data.vendor_name || ""}</span>
              </div>
              {data.isPick >= 1 && (
                <div className="field_info">
                  <img
                    src="/Images/order-details-order-status.png"
                    width="20%"
                  />
                </div>
              )}
            </div>
          </div>

          {data.isPick < 1 && (
            <div>
              {/* <Button
                style="Decline"
                title="Replace"
                onclick={() => setOpenRepalce(true)}
              /> */}
              <Button
                style="Confirm"
                title="Pick"
                onclick={() => setOpen(true)}
              />
            </div>
          )}
        </div>
      </ClickNHold>
      {open && (
        <ConfirmPick
          open={open}
          setOpen={setOpen}
          handelConfirmPick={handelConfirmPick}
        />
      )}
    
    </>
  );
};
