import { QueryClient } from '@tanstack/query-core';
import { toast } from 'react-toastify';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
      suspense: true,
      retry: false,
    },
    mutations: {
      retry: false,
      onError: (err, variables, recover) => {
        
      },
    },
  },
});
