import React, { useContext, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "../../../../Components/Button/Button";
import { DialogTitle } from "@mui/material";
import {getDataInCache} from "../../../../MiddleWare/CacheHelper";

export const CacheVendor = ({ open, setOpen, handelCache}) => {
  const handleClose = () => {
    setOpen(false);
    
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle fontSize={"18px"}>last update :{getDataInCache("fetchAt")}</DialogTitle>
      <div>
        <span style={{margin:"0px 20px",display:"flex",justifyContent:"center"}}>
          <Button style="Confirm" title="Update" onclick={()=>handelCache()}/>
        </span>
      </div>
    </Dialog>
  );
};
