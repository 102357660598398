import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Components/Context";
import { FetchAPI } from "../../../Components/Utils/FetchAPI";
import {API_URL} from "../../../Firebase/API_URL";
import { NavBar } from "./NavBar";
import { OrderTable } from "./OrderTable";
import {useQuery} from "@tanstack/react-query";
import {fetchOrdersStatics} from "../../../Api/fetchers";
export const Dashboard = () => {
  const { pendingOrders } = useContext(AuthContext);
  const [search, setSearch] = useState("");
  const [wearhouse, setWearhouse] = useState(null);
  const [totalNewOrder, setTotalNewOrder] = useState(0);

  let data = pendingOrders.filter((e) =>
    wearhouse ? e.in_warehouse == wearhouse.value : e
  );
  const { refetch, isFetching ,data:ordersStatics} = useQuery({
    queryKey: ['fetchOrdersStatics'],
    queryFn: () =>  fetchOrdersStatics(),
    onSuccess:(response)=> {
      console.log(response);
    },
    onError:(error)=>{
      console.log(error)
    }
 
  });
console.log(ordersStatics)
  return (
    <>
      <NavBar
        setSearch={setSearch}
        orders={data}
      />
      <OrderTable
        orders={data.filter((e) =>
          e.vendor_order_with_classification.includes(search || "")
        )}
        wearhouse={wearhouse}
        setWearhouse={setWearhouse}
      />
      <div className="footer">
        <div>
          <span className="title">Total New Order : </span>
          <span>{ordersStatics.newOrdersCount	 || 0}</span>
        </div>
        <div>
          <span className="title">Total confirmed : </span>
          <span>{ordersStatics.upcomingOrdersCount	 || 0}</span>
        </div>
        <div>
          <span className="title">Total Processing : </span>
          <span>{ordersStatics.confirmedOrdersCount	 || 0}</span>
        </div>
      </div>
    </>
  );
};