import dayjs from "dayjs";
import { useContext, useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Button from "../../../Components/Button/Button";
import { AuthContext } from "../../../Components/Context";
import Input from "../../../Components/Input/Input";
import { FetchAPI } from "../../../Components/Utils/FetchAPI";
import { API_URL } from "../../../Firebase/API_URL";
import { ordersCollection } from "../../../Firebase/Collections";
import { setDocumnet } from "../../../MiddleWare/Firebase-functions";
import customStyles from "../../../styles/custom_modal.json";
import { OrderReceipt } from "../../../Components/Template/OrderReceiptPrint/OrderReceipt";
import ReactToPrint from "react-to-print";
import { CardNote } from "../../../Data/ReceiptNote";
import { PrintButton } from "../../../Components/Button/PrintButton";

export const CustomerSmsModal = ({ isOpen, setIsOpen, order }) => {
  const { currentUser, setLoading } = useContext(AuthContext);
  const [customer, setCustomer] = useState(null);
  const [pin, setPin] = useState("");
  const [note, setNote] = useState("");
  const refComp = useRef();

  const fetchDetails = async () => {
    setLoading(true);
    let result = await FetchAPI(
      `${API_URL}/get_order_by_id?orderId=${order.order_id}&email=${order.customer_email_m}`,
      {
        method: "GET",
      }
    );
    if (!result.status) return;
    setLoading(false);
    console.log(result.data);
    setCustomer(result.data);
  };
  useEffect(() => {
    fetchDetails();
  }, []);
  console.log(currentUser);
  const handelSubmit = async () => {
    setLoading(true);

    var raw = JSON.stringify({
      pin: pin,
      phone: customer.phone,
      sku: order.sku,
      vendor: currentUser.vendor_label,
    });
    var myHeaders = new Headers();
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    myHeaders.append("Content-Type", "application/json");
    let result = await FetchAPI(`${API_URL}/PIN_sender`, requestOptions);
    console.log(result);
    if (result.status) {
      let checkQty = order.sub_order.split("-");

      await setDocumnet(
        ordersCollection,
        `${order.sub_order}-${dayjs(new Date()).format("YYMMDDhhmmss")}`,
        {
          pin,
          orderId: order.order_id,
          createBy: currentUser.name,
          customerName: customer.fullName,
          customerPhone: customer.phone,
          location: customer.address,
          orderDate: order.created_at,
          sendPinAt: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
          collectStatus: false,
          vendorName: order.vendorName,
          searchTerm: [order.order_id, pin, customer.phone.replace("+964", "")],
        }
      );
      toast.success(result.data);
      // setIsOpen(false);
    } else {
      toast.error("حدث خطا تاكد من الرقم");
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div>
          <div className="Modal_header">
            <div>Add New Sms</div>
            <img
              onClick={() => setIsOpen(false)}
              width="20"
              height="30"
              src="/Images/close_modal.svg"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="Modal_content">
            <div>
              <p>Name</p>
              <input
                className="input"
                placeholder="Name"
                type="text"
                defaultValue={customer?.fullName}
              />
            </div>
            <div>
              <p>Account Name</p>
              <input
                className="input"
                placeholder="Account Name"
                type="text"
                defaultValue={customer?.accountName || ""}
              />
            </div>

            <div>
              <p>Location</p>
              <input
                className="input"
                placeholder="Location"
                type="text"
                defaultValue={customer?.address}
              />
            </div>
            <div>
              <p>Total Orders</p>
              <input
                className="input"
                placeholder="Total Orders"
                type="text"
                defaultValue={customer?.totalOrder}
              />
            </div>
            <div>
              <p>Pin</p>
              <Input
                style="input"
                placeholder="Pin"
                type="text"
                defaultValue={pin}
                onchange={(e) => setPin(e)}
              />
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "20px", display: "flex", alignItems: "center" }}
        >
          <Button
            title={`Create`}
            style="button"
            color="c70b44d"
            onclick={() => handelSubmit()}
          />
          <ReactToPrint
            trigger={() => (
              <button className="print-button">
                <span className="print-icon"></span>
              </button>
            )}
            content={() => refComp.current}
          />
        </div>
      </Modal>
      <div ref={refComp}>
        <OrderReceipt
          task={{
            ...customer,
            ...order,
          }}
          note={CardNote}
        />
      </div>
    </>
  );
};
