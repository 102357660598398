export const addDataIntoCache=(cacheName,response)=>{
    const data =  JSON.stringify(response);
    localStorage.setItem(cacheName, data);
   
}
export const getDataInCache=(cacheName)=>{
    const data=JSON.parse(localStorage.getItem(cacheName))||[];
    return data;
}
export const addDataIntoSesstion=(sessionName,response)=>{
    const data =  JSON.stringify(response);
    sessionStorage.setItem(sessionName, data);
   
}
export const getDataInSession=(sessionName)=>{
    const data=JSON.parse(sessionStorage.getItem(sessionName))||[];
    return data;
}