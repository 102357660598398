import {httpClient} from "../Config/axios";

export const replaceOrder = async (data) => {
    return await httpClient.put(`/replaceOrder`, { ...data });
  };
  export const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file); 
    
    const response = await httpClient.post("/uploadImage", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    
    return response.data;
  };