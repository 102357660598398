import React from "react";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import 'reactjs-popup/dist/index.css';
import { createRoot } from "react-dom/client";
import 'react-photo-view/dist/react-photo-view.css';
import 'reactjs-popup/dist/index.css';

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <App tab="home" />
  </BrowserRouter>
);
