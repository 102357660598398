import { FetchAPI } from "../Components/Utils/FetchAPI";
import {API_URL} from "../Firebase/API_URL";
import {addDataIntoSesstion, getDataInSession} from "./CacheHelper";

export const FetchMagentoVendors = async () => {
  if (sessionStorage.getItem("MagentoVendors")) {
    var data = getDataInSession("MagentoVendors");
    return data;
  } else {
    var { data } = await FetchAPI(`${API_URL}/getAllVendors`, {
      method: "GET",
    });
    data = data.map((e) => ({ ...e, label: e.name, value: e.name }));
    addDataIntoSesstion("MagentoVendors", data);
    return data;
  }
};


