import { useEffect, useState } from "react";
import Input from "../../../Components/Input/Input";
import { FetchMagentoVendors } from "../../../MiddleWare/MagneoVendors";
import Modal from "react-modal";
import Select from "react-select";
import customStyles from "../../../styles/custom_modal.json";
import { setDocumnet } from "../../../MiddleWare/Firebase-functions";
import { vendorsCollections } from "../../../Firebase/Collections";
import Button from "../../../Components/Button/Button";
import {toast} from "react-toastify";
export const AddVendor = ({ isOpen, setIsOpen }) => {
  const [vendor, setVendor] = useState(null);
  const [car, setCar] = useState("");
  const [arabicName, setArabicName] = useState("");
  const [magentVendors, setMagentoVendors] = useState([]);
  const getData = async () => {
    let data = await FetchMagentoVendors();
    setMagentoVendors([...data]);
  };
  useEffect(() => {
    getData();
  }, []);
  const handelSubmit = async () => {
    if(!car) return;
    await setDocumnet(vendorsCollections, vendor.name, {
      name: vendor?.label,
      car: car.toString(),
      vendorId:vendor.id,
      address:vendor.address||"",
      arabicName:arabicName||"",
      phone:vendor.mobile||0
    });
    setIsOpen(false);
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div>
          <div className="Modal_header">
            <div>Add New Vendor</div>
            <img
              onClick={() => setIsOpen(false)}
              width="20"
              height="30"
              src="/Images/close_modal.svg"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="Modal_content">
            <div>
              <span>Name</span>
              <Select
                options={magentVendors}
                name="colors"
                value={vendor}
                onChange={(val) => {
                  setVendor(val);
                }}
                isClearable={true}
                placeholder="Status"
              />
            </div>
            <div>
              <p>Car</p>
              <Input
                style="input"
                placeholder="Car"
                type="text"
                defaultValue={car}
                onchange={(e) => setCar(e)}
              />
            </div>
            <div>
              <p>Name in Arabic</p>
              <Input
                style="input"
                placeholder="Car"
                type="text"
                defaultValue={arabicName}
                onchange={(e) => setArabicName(e)}
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            title={`Create`}
            style="button"
            color="c70b44d"
            onclick={() => handelSubmit()}
          />
        </div>
      </Modal>
    </>
  );
};
