import {CardsList} from "../VendorPickUp/CardsList"
import {NavBar} from "../VendorPickUp/Navbar"
import {VendorsList} from "../VendorPickUp/VendorsList"
import {FetchAgentVendors} from "../VendorPickUp/FetchAgentVendors"
import {useCallback, useContext, useEffect, useState} from "react"
import {AuthContext} from "../../../Components/Context"
import {Route, Routes} from "react-router"
export const ManageCars=()=>{

    const { setLoading, currentUser } = useContext(AuthContext);
    const [tamataVendors, setTamataVendors] = useState([]);
    const fetVendorOrders = useCallback(async (isRefresh = false) => {
  
      setLoading(true);
      var vendorsResponse = await FetchAgentVendors(currentUser,isRefresh);
      setTamataVendors(vendorsResponse);
       setLoading(false);
    }, []);
  
    useEffect(() => {
      fetVendorOrders();
    }, [fetVendorOrders]);
    return(
        <>
       <div className="vendorPick">
        <NavBar fetVendorOrders={fetVendorOrders} />
        <div className="vendor_pick_contaienr">
          <Routes>
            <Route
              path=""
              element={<VendorsList tamataVendors={tamataVendors} />}
            />
            <Route
              path="/OrdersPick/:id"
              element={<CardsList setTamataVendors={setTamataVendors} />}
            />
          </Routes>
        </div>
      </div>
        
        </>
    )
}