import {useState} from "react";
import { Route, Routes } from "react-router";
import {toast} from "react-toastify";
import {ReadExcel} from "../../Components/ReadExcel";
import {vendorsCollections} from "../../Firebase/Collections";
import {updateDocument} from "../../MiddleWare/Firebase-functions";

import { PrivateRoute } from "../../Route/PrivateRoute";
import { ROLES } from "../../Services/Role";
import {Operation} from "../Operation";
import { VendorPanel } from "../Vendor";

export const Home = () => {
;
  return (
    <>

      <div className="main_contnet">
        <Routes>
          <Route
            path="*"
            element={
              <PrivateRoute roles={[ROLES.vendor]}>
                <VendorPanel />
              </PrivateRoute>
            }
          />
          <Route
            path="/tamata_opration/*"
            element={
              <PrivateRoute roles={[ROLES.operation,"agent"]}>
                <Operation />
              </PrivateRoute>
            }
          />
          
        </Routes>
      </div>
    </>
  );
};
