import dayjs from "dayjs";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../Components/Button/Button";
import { AuthContext } from "../../../Components/Context";
import Input from "../../../Components/Input/Input";
import { orderV2sCollection } from "../../../Firebase/Collections";
import {timeago} from "../../../Helper/HelperFun";
import {
  setDocumnet,
  updateArrayDoc,
} from "../../../MiddleWare/Firebase-functions";


export const CreateNote = ({ order}) => {
  const { currentUser ,setPendingOrders} = useContext(AuthContext);
  const [note, setNote] = useState("");
  const handelNewOrder = async () => {
    if (!note) return;

    if (order.notes.length == 0) {
      await setDocumnet(
        orderV2sCollection,
        order.vendor_order_with_classification,
        {
          notes: [
            {
              note: note,
              createAt: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
              createBy: currentUser.name,
            },
          ],
        }
      );
    } else {
      await updateArrayDoc(
        orderV2sCollection,
        order.vendor_order_with_classification,
        "notes",
        {
          note: note,
          createAt: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          createBy: currentUser.name,
        }
      );
    }
    setPendingOrders((prev) => {
      let index = prev.findIndex(
        (e) =>
          e.vendor_order_with_classification ==
          order.vendor_order_with_classification
      );
      prev[index].notes = [
        ...prev[index].notes,
        {
          note: note,
          createAt: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          createBy: currentUser.name,
          inc: prev[index].notes?.length + 1,
        },
      ];
      return [...prev];
    });

    setNote("");
    toast.success(note);
  };

  return (
    <>
      <div className="noteContainer">
        <div className="chat_note">
          {order.notes?.map((e, index) => (
            <div key={index}>
              <span style={{display:"flex",justifyContent:"space-between"}}>
                <span>
                {e.createBy} : {e.note}
                </span>
               
                <span style={{fontSize:"9px",color:"#A4A4A4"}}>
                 {timeago(new Date(e.createAt))||""}  ago
                 
                </span>
              </span>
            
            </div>
          ))}
        </div>
        <Input
          style="inputNote"
          placeholder={"....  ملاحظة"}
          value={note}
          onchange={(e) => setNote(e)}
        />
        <Button
          color="ceb0028"
          style="button submit_btn"
          title={"SUBMIT"}
          onclick={() => handelNewOrder(order)}
        />
      </div>
    </>
  );
};
