import {httpClient} from "../Config/axios";

export const fetchOrders = async (query) => {
    return (await httpClient.get('/pendingOrders',{
        params:{
            ...query
        }
    })).data;
  };
export const fetchOrdersStatics = async () => {
  console.log("---------------------")
    return (await httpClient.get('/orderStatistics')).data;
  };