import {useState} from "react";
import {Outlet, Route, Routes} from "react-router"
import Input from "../../../Components/Input/Input";
import {ManageCars} from "./ManageCars";
import {ManangeVendors} from "./ManageVendors"
import {CancelOrder} from "./CancelOrder";

export const PickUpsAdmin=()=>{
    return (
      <>
       
        <Routes>
          <Route path="/" element={<ManangeVendors  />} />
          <Route path="/cars/*" element={<ManageCars  />} />
          <Route path="/cancel/*" element={<CancelOrder  />} />
        </Routes>
          <Outlet/>
      </>
    );
}