export const custom_style_select = () => {
    return {
      control: base => ({
        ...base,
        fontFamily: "Times New Roman"
      }),
      menu: base => ({
        ...base,
        fontFamily: "Times New Roman"
      }),
        control: (provided, state) => ({
          ...provided,
          background: "#f5f5f5",
          borderColor: "#9e9e9e",
          borderRaduis:"8px",
          height: "40px",
          width: "150px",
          border: 0,
          boxShadow: state.isFocused ? null : null,
          margin: "0 10px",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: "30px",          

            padding: "0 6px",
          }),
      
          input: (provided, state) => ({
            ...provided,
            margin: "0px",
          }),
      
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: "40px",

          }),
        
      };
};

export const custom_select_country = () => {
  return {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "30px",
      height: "30px",
      width: "100%",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
  };
};

export const empty_select = () => {
  return {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#fff",
      height: "400px !important",
      overFlow:"visible !important",
      height: "30px",
      width: "100%",
      flex:"1",
      fontSize:"12px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
  };
};
export const vendor_list_style = () => {
  return {
    control: (provided, state) => ({
      ...provided,
      background: "#F2F2F2",
      // borderColor: "red",
      minHeight: "30px",
      overFlow:"visible !important",
      height: "30px",
      fontSize:"14px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",

    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#F2F2F2",
      border:"2px solid #eee",
      fontSize:"14px",
      color:"#000"
      // backgroundColor: state.isSelected ? "rgba(189,197,209,.3)" : "white",
  }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",

    }),
  };
};
