import {IconButton} from "@mui/material";
import { getDocs, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useContext, useEffect, useState ,useRef} from "react";
import Button from "../../../Components/Button/Button";
import { ExportExcel } from "../../../Components/ExportExcel";
import Input from "../../../Components/Input/Input";
import { ordersCollection } from "../../../Firebase/Collections";
import CollectPopup from "./CollectPayment";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import {AuthContext} from "../../../Components/Context";
import ReactToPrint from "react-to-print";
import {getMultiDoc} from "../../../MiddleWare/Firebase-functions";

export const OrdersTracking = () => {
  const [search, setSearch] = useState("");
  const [orders, setOrders] = useState([]);
  const[selectOrder,setSelectOrder]=useState(null)
  const[open,setOpen]=useState(false)
  const {setLoading,currentUser}=useContext(AuthContext);


  // const fetchOrders = () => {
  //   const q = query(
  //     ordersCollection,
  //     where("vendorName", "==", currentUser.vendorName||""),
  //     orderBy("sendPinAt", "desc"),
  //     limit(2)
  //   );
  //   const unsubscriber = onSnapshot(q, async (snapshot) => {
  //     var myData = [];
  //     snapshot.docs.map((doc) => {
  //       myData.push({ ...doc.data(), id: doc.id });
  //     });
  //     setOrders([...myData]);
  //   });
  //   return unsubscriber;
  // };
  // useEffect(() => {
  //   fetchOrders();
  // }, []);

  const ExportData=async()=>{
    setLoading(true)
    let q=query(
      ordersCollection,
      where("vendorName", "==", currentUser.vendorName),
      orderBy("sendPinAt", "desc"),
      limit(900)
    );
  try {
    var data=[];
    const mydata = await getDocs(
      q
      );
      mydata.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      console.log([...data])
      ExportExcel(data, currentUser.vendorName)
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    return;
  }
  };

  const handelSearch=async(event)=>{
    if(event.key === 'Enter'){
      console.log('enter press here! ')
      const result= await getMultiDoc(ordersCollection,event.target.value,"searchTerm","array-contains");
      if(!result)return;
      setOrders(result)
      console.log(result)
    }
  }
  return (
    <>
      <div style={{ width: "90%" }}>
        <div className="container" st>
          <div className="navbar-wrapper">
            <div className="logo-search-wrapper">
            <input
            placeholder="Search For Invoice"
            value={search}
            type="text"
            className="input search"
            onKeyPress={(e) => handelSearch(e)}
            onChange={(e) => setSearch(e.target.value)}
          />
 
            </div>
            <div>
              <span style={{ marginRight: "10px" }}>
                <Button
                  color="c70b44d"
                  style="button c70b44d"
                  id="log-out"
                  onclick={() => ExportData()}
                  title="Export"
                />
              </span>
            </div>
          </div>

          <div className="table_container">
            <div className="thead">
              <span>#</span>
              <span>Order Id</span>
              <span>Customer Phone</span>
              <span>Customer Name</span>
              <span>Order Date </span>
              <span>Send At</span>
              <span>Pin</span>
              <span>status</span>
            </div>
            <div className="row_odd">
              {orders?.map((e, index) => (
                <div className="body_container" key={index * 4}>
                  <span className="small_cell">{index + 1}</span>
                  <span className="small_cell">{e.id}</span>
                  <span className="small_cell">{e.customerName}</span>
                  <span className="small_cell">{e.customerPhone}</span>
                  <span className="small_cell">{e.orderDate}</span>
                  <span className="small_cell">{e.sendPinAt}</span>
                  <span className="small_cell">{e.pin}</span>
              
                  <span
                    onClick={() => {
                      setOpen(true);
                      setSelectOrder(e);
                    }}
                  >
                    <IconButton aria-label="delete">
                      {e.collectStatus ? (
                        <CreditScoreIcon color="success" />
                      ) : (
                        <CreditCardIcon color="error" />
                      )}
                    </IconButton>{" "}
                  </span>{" "}
                </div>
              ))}
            </div>
          </div>
        </div>
        {open && <CollectPopup open={open} setOpen={setOpen} order={selectOrder} />}

      </div>
    
    </>
  );
};
