import { useContext, useEffect, useState, useRef } from "react";
import Button from "../../../Components/Button/Button";
import { AuthContext } from "../../../Components/Context";
import { ExportExcel } from "../../../Components/ExportExcel";
import { cancelOrderCollection } from "../../../Firebase/Collections";
import { getMultiDoc } from "../../../MiddleWare/Firebase-functions";
import { FetchMagentoVendors } from "../../../MiddleWare/MagneoVendors";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import { ItemBarcode } from "./ItemBarcode";

export const CancelOrder = () => {
  const { setLoading } = useContext(AuthContext);
  const [subOrders, setSubOrders] = useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [search, setSearch] = useState(null);
  const [selectedBarcode, setSelectedBarcode] = useState("");
  const refComp = useRef();

  const fetchSubOrders = async () => {
    if (!search) return;
    setLoading(true);
    let payload = await getMultiDoc(
      cancelOrderCollection,
      search.value,
      "vendorName",
      "=="
    );
    console.log(payload);
    if (payload.length == 0) {
        setLoading(false)
        return;
    };
    setSubOrders([...payload]);
    setLoading(false);
  };
  const FetchVendors = async () => {
    let data = await FetchMagentoVendors();
    setAllVendors([...data]);
  };
  useEffect(() => {
    FetchVendors();
  }, []);
  useEffect(() => {
    fetchSubOrders();
  }, [search]);
  const Print = (id) => {
    setSelectedBarcode(id)
    var content = document.getElementById("Invoice_print");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };
  return (
    <>
      <div className="container">
        <div className="navbar-wrapper">
          <div className="logo-search-wrapper">
            <div className="logo" style={{ marginRight: "10px" }}>
              <img width="80px" alt="logo" src="/Images/tamata_logo.svg" />
            </div>
          </div>
        </div>
      </div>
      <div className="table_container">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div className="Modal_content">
            <div>
              <Select
                options={allVendors.map((e) => ({
                  value: e.name,
                  label: e.name,
                }))}
                value={search}
                onChange={(val) => {
                  setSearch(val);
                }}
                isClearable={true}
                placeholder="Status"
              />
            </div>
          </div>
          <Button
            color="c70b44d"
            style="button c70b44d"
            id="log-out"
            onclick={() => ExportExcel(subOrders, "Tamata Order")}
            title={`Export Excel`}
          />
        </div>
        <div className="thead">
          <span className="small_cell">OrderId</span>
          <span className="small_cell">SubOrderId</span>
          <span className="small_cell">VendorName</span>
          <span className="small_cell">Qty</span>
          <span className="small_cell">Sku</span>
          <span className="small_cell">Image</span>
          <span className="small_cell"></span>
        </div>
        <div className="row_odd">
          {subOrders.length !== 0 ? (
            subOrders.map((e, index) => (
              <div
                className="body_container"
                key={index * 4}
                style={{
                  height: "5%",
                  margin: "10px 2px",
                  borderRadius: "2px",
                }}
              >
                <span className="small_cell">{index + 1}</span>
                <span className="small_cell">{e.orderId}</span>
                <span className="small_cell">
                  {e.vendor_order_with_classification}
                </span>
                <span className="small_cell">{e.vendorName}</span>
                <span className="small_cell">{e.qty}</span>
                <span className="small_cell">{e.sku}</span>
                <span className="small_cell">{e.image_url}</span>
                <span className="small_cell"onClick={() => Print(e.vendor_order_with_classification)}>
<button style={{
    backgroundColor:"blue",
    padding:"3px",
    border:"none",
    width:"90px",
    color:"white",
    borderRadius:"10px",
    cursor:"pointer"
}}>Print</button>                </span>
              </div>
            ))
          ) : (
            <div>NO Orders</div>
          )}
        </div>
      </div>
      {selectedBarcode && (
        <div id="Invoice_print" ref={refComp}>
       <ItemBarcode id={selectedBarcode}/>
      </div>
      )}
    </>
  );
};
