import { useEffect, useState } from "react";
import Input from "../../../Components/Input/Input";
import { FetchMagentoVendors } from "../../../MiddleWare/MagneoVendors";
import Modal from "react-modal";
import Select from "react-select";
import customStyles from "../../../styles/custom_modal.json";
import Button from "../../../Components/Button/Button";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { replaceOrder, updateImage, uploadImage } from "../../../Api/mutation";

export const ReplaceOrderModal = ({ isOpen, setIsOpen, subOrderId }) => {
  const [vendor, setVendor] = useState(null);
  const [sku, setSku] = useState("");
  const [cost, setCost] = useState(0);
  const [magentVendors, setMagentoVendors] = useState([]);
  const [imageUrl,setImageUrl]=useState(null)

  const [errors, setErrors] = useState({}); 

  const getData = async () => {
    let data = await FetchMagentoVendors();
    setMagentoVendors([...data]);
  };

  useEffect(() => {
    getData();
  }, []);

  const replaceMutation = useMutation({
    mutationFn: async () =>
      replaceOrder({
        sku,
        vendorId: vendor.id,
        cost,
        subOrderId,
        imageUrl
      }),
    onSuccess: () => {
      // setIsOpen(false);
      setVendor(null);
      setCost(0);
      setSku("");
      setImageUrl(null)
      setIsOpen(false)
      
      toast.success("Order replaced successfully!");
    },
    onError: () => {
      toast.error("Failed to replace order");
    },
  });
  const uploadImageMutation = useMutation({
    mutationFn:  uploadImage,
    onSuccess: (data) => {
     setImageUrl(data)
      toast.success("Upload Image  successfully!");
    },
    onError: () => {
      toast.error("Failed ");
    },
  });

  const validateFields = () => {
    const newErrors = {};
    if (!vendor) newErrors.vendor = "Vendor is required.";
    if (!sku) newErrors.sku = "SKU is required.";
    if (!cost || cost <= 0) newErrors.cost = "Cost must be greater than zero.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; 
  };

  const handleSubmit = async () => {
    if (validateFields()) {
      replaceMutation.mutateAsync();
    }
  };
const handleUploadImage=async(file)=>{
  console.log(file)
  const maxSizeInBytes = 1 * 1024 * 1024;

  if (file && file.size > maxSizeInBytes) {
    alert("File size must be 1MB or smaller!");
   
    return;
  }
  uploadImageMutation.mutateAsync(file)
}
  return (
    <>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        contentLabel="Replace Order Modal"
        style={customStyles}
      >
        <div>
          <div className="Modal_header">
            <div>Replace Order</div>
            <img
              onClick={() => setIsOpen(false)}
              width="20"
              height="30"
              src="/Images/close_modal.svg"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="Modal_content">
            <div>
              <span>Vendor Name</span>
              <Select
                options={magentVendors}
                value={vendor}
                onChange={(val) => setVendor(val)}
                isClearable={true}
                placeholder="Select Vendor"
              />
              {errors.vendor && <p style={{color:"red"}}>{errors.vendor}</p>}
            </div>
            <div>
              <p>SKU</p>
              <Input
                style="input"
                placeholder="SKU"
                type="text"
                defaultValue={sku}
                onchange={(e) => setSku(e)}
              />
              {errors.sku && <p style={{color:"red"}}>{errors.sku}</p>}
            </div>
            <div>
              <p>Cost</p>
              <Input
                style="input"
                placeholder="Cost"
                type="number"
                defaultValue={cost}
                onchange={(e) => setCost(Number(e))}
              />
              {errors.cost && <p style={{color:"red"}}>{errors.cost}</p>}
            </div>
            <div>
              <p>Image</p>
              <input type="file" disabled={uploadImageMutation.isLoading}   accept=".png, .jpeg, .jpg"
 onChange={(e)=>handleUploadImage(e.target.files[0])}/>
            
              {uploadImageMutation.isLoading && <p >...Loading</p>}
            </div>
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            title={`Create`}
            style="button"
            color="c70b44d"
            onclick={handleSubmit}
            isLoading={replaceMutation.isLoading}
            disabled={replaceMutation.isLoading ||uploadImageMutation.isLoading}
          />
        </div>
      </Modal>
    </>
  );
};
