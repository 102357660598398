import { AiOutlineDashboard } from "react-icons/ai";

import { MdAccountBalance } from "react-icons/md";
import {ROLES} from "../../../../../Services/Role";

export const SidebarContent = [
  {
    title: "Home",
    url: "/",
    icon: <AiOutlineDashboard />,
    role: [ROLES.vendor],
  },
  {
    title: "Vendor Accounting",
    url: "/ordes_tracking",
    role: [ROLES.vendor],

    icon: <MdAccountBalance />,
  },
];
