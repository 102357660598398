import {
  addDoc,
  arrayUnion,
  deleteDoc,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../Firebase/Config";
import firebase from "firebase/compat/app";

export const getSingleDoc = async (collection, payload) => {
  if (!collection || !payload) return;
  var docSnap;
  try {
    var docSnap = await getDoc(doc(collection, payload));
    if (docSnap.exists()) return { ...docSnap.data(), id: docSnap.id };
  } catch (error) {
    console.log(error);
    return;
  }
};

export const setDocumnet = async (collection, id, payload) => {
  console.log(collection,id,payload);
  if (!collection || !id) return;
  try {
    await setDoc(
      doc(collection, id),
      {
        ...payload,
        myUpdate:serverTimestamp()
      },
      { merge: true }
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getAllDoc = async (collection) => {
  if (!collection) return;
  var data = [];
  try {
    const mydata = await getDocs(collection);
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return data;
  } catch (error) {
    console.log(error);
    return;
  }
};
export const updateDocument = async (collection, id, data) => {
  if (!collection || !id) return;
  try {
    await updateDoc(doc(collection, id), {
      ...data,
    });
  } catch (error) {
    console.log(error);
    return;
  }
};
export const addData2Document = async (collection, id, data) => {
  if (!collection || !id) return;
  try {
    await addDoc(doc(collection, data), {
      ...data,
    });
  } catch (error) {
    console.log(error);
    return;
  }
};

export const getQueryDocs = async (collection, field, queryCond, payload) => {
  if (!collection || !queryCond || !field) return;
  var data = [];
  try {
    while (payload.length) {
      const batch = payload.splice(0, 10);
      const mydata = await getDocs(
        query(collection, where(field, queryCond, [...batch]))
      );
      mydata.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
    }
    return Promise.all(data).then((content) => content.flat());
  } catch (error) {
    console.log(error);
    return;
  }
};
export const getMultiDoc = async (collection, payload, field,qeruyOps) => {
  if (!collection) return;
  var data = [];

  try {
    const mydata = await getDocs(
      query(collection, where(field, qeruyOps, payload))
    );
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    console.log([...data])
    return [...data];
  } catch (error) {
    console.log(error);
    return;
  }
};

export const updateArrayDoc = async (collection, id, field, payload) => {
  if (!collection || !id || !payload) return;
  try {
    await updateDoc(doc(collection, id), {
      [field]: arrayUnion(payload),
    });
  } catch (error) {
    console.log(error);
    return;
  }
};
export const deleteDocument = async (collection, id) => {
  if (!collection || !id) return;
  try {
    await deleteDoc(doc(collection, id));
    console.log("done")
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
// export async function getContentById(collection, ids) {
//   // don't run if there aren't any ids or a path for the collection
//   if (!ids || !ids.length) return [];

//   const collection = db.collection("orders");
//   console.log(collection);
//   const batches = [];
//   while (ids.length) {
//     // firestore limits batches to 10
//     const batch = ids.splice(0, 10);

//     // add the batch request to to a queue
//     batches.push(
//       collection
//         .where(documentId(), "in", [...batch])
//         .get()
//         .then((results) =>
//           results.docs.map((result) => ({
//             /* id: result.id, */ ...result.data(),
//           }))
//         )
//     );
//   }

//   // after all of the data is fetched, return it
//   return Promise.all(batches).then((content) => content.flat());
// }
