import Input from "../../../Components/Input/Input";
import Button from "../../../Components/Button/Button";
import {ExportExcel} from "../../../Components/ExportExcel";
import {useCallback, useContext, useEffect, useState} from "react";
import {ReqOrdersTypes} from "../../../MiddleWare/ReqOrdersType";
import {AuthContext} from "../../../Components/Context";
import tookanStatus from "../../../Data/tookanStatus.json";
import Select from "react-select";
import {custom_style_select} from "../../../styles/custom_select";
import {toast} from "react-toastify";
import orderStatusOption from "../../../Data/OrderStatus.json";
import {addHours, getHours} from "../../../Helper/HelperFun";
import dayjs from "dayjs";
import {auth} from "../../../Firebase/Config";
import {useNavigate} from "react-router";
export const NavBar = ({setSearch, setOrderStatus, orderStatus}) => {
  const navigate = useNavigate();
  const {currentUser, setCurrentUser} = useContext(AuthContext);
  const [totalOrders, setTotalOrders] = useState([]);

  const exportOrders = () => {
    if (totalOrders.length === 0) {
      toast.warning("Please Wait.....");
      return;
    }
    let data = totalOrders.map((r) => ({
      orderId: r.orderId,
      subOrder: r.subOrder,
      customer_email: r.customer_email_m,
      customer_phone: r.tokan?.customer_phone,
      item_name: r.name,
      item_count: r.total_item_count,
      status: r.tokan ? tookanStatus[r.tokan?.job_status] : r.status_m,
      create_at: dayjs(addHours(r.created_at, 3)).format("YYYY-MM-DD HH:mm:ss"),
      confirmation_date: dayjs(addHours(r.confirmation_date, 3)).format("YYYY-MM-DD HH:mm:ss"),
      ship_at: dayjs(addHours(r.shippingTime, 3)).format("YYYY-MM-DD HH:mm:ss") || " ",
      upload_Delivery_at: r.tokan?.creation_datetime,
      completed_at: r.tokan?.completed_datetime,
      isNight:
        getHours(addHours(r.created_at, 3)) < 9 ||
        getHours(addHours(r.created_at, 3)) >= 21,
    }));
    ExportExcel(data);
  };

  const handelLogout = () => {
    navigate("/Login");
    setCurrentUser(null);
    auth.signOut();
  };
  return (
    <>
      <div className="container">
        <div className="navbar-wrapper">
          <div className="logo-search-wrapper">

            <Input
              id="search"
              style="input search"
              placeholder="Searching.... "
              type="text"
              onchange={(e) => setSearch(e)}
            />
            {/* <Select
              styles={custom_style_select()}
              className="react_select"
              placeholder="Status"
              options={orderStatusOption}
              name="grup"
              val={orderStatus}
              isClearable={true}
              onChange={(val) => setOrderStatus(val)}
            /> */}
          </div>
          <div>
            <span style={{marginRight: "10px", display: "flex"}}>

              <Button
                color="c70b44d"
                style="button c70b44d"
                id="log-out"
                onclick={() => exportOrders()}
                title="Export"
              />

            

            </span>

          </div>
        </div>
      </div>
    </>
  );
};
