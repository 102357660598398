
export default function Button(props) {
    return (
      <button
        className={props.style + " " + props.color}
        onClick={props.onclick}
        onSubmit={props.onsubmit}
        type={props.type}
        disabled={props.disabled}
      >
        {props.isLoading?<>....Loading</>:props.title}
    
      </button>
    );
  }