import {Route, Routes} from "react-router";
import SideBar from "./Components/Layout/SideBar";
import { Home } from "./Home";
import { OrdersTracking } from "./OrdersTracking";

export const VendorPanel = () => {
  return (
    <>
      <div className="app">
        <SideBar />
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/ordes_tracking" element={<OrdersTracking />} />
        </Routes>
      </div>
    </>
  );
};
{
}
