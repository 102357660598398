import { toast } from "react-toastify";

export const FetchAPI = async (url, option) => {
  try {
    const response = await fetch(url, option);
    const res = await response.json();
    return res;
  } catch (error) {
    console.log(error);
    toast.error("حدث خطا ......");
    return;
  }
};
