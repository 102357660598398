import {useContext, useEffect} from "react";
import {Navigate, Route, Routes, useNavigate} from "react-router";
import {AuthContext} from "../../Components/Context";
import {PickUpsAdmin} from "./admin";
import {TamataTracking} from "./TrackingOrder";
import {VendorPickUp} from "./VendorPickUp";

export const Operation = () => {
    const {currentUser} = useContext(AuthContext);
    const navigate = useNavigate();

    return (
        <>
            <Routes>
                <Route path="*" element={<TamataTracking />} />

                {/* For Agents */}
                <Route path="vendorPickups/*" element={<VendorPickUp />} /> 
            </Routes>
        </>
    );
};
