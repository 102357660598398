export default function Input(props) {
    return (
      <input
        className={props.style}
        placeholder={props.placeholder}
        type={props.type}
        name={props.name}
        onChange={(e) => {
          props.onchange(e.target.value);
        }}
        accept={props.accept}
        value={props.value}
        defaultValue={props.defaultValue}
        disabled={props.disabled||false}
        autoComplete={props.autoComplete}
      ></input>
    );
  }