import { useContext, useState, useEffect } from "react";
import Select from "react-select";
import { vendor_list_style } from "../../../styles/custom_select";
import { BiPhoneCall } from "react-icons/bi";
import { IconContext } from "react-icons/lib";
import { useNavigate } from "react-router";
import { VendorCard } from "./VendorCard";
import { AuthContext } from "../../../Components/Context";
import { UserRole } from "../../../Data/UsersRole";

export const VendorsList = ({ tamataVendors }) => {
  const { currentUser, vendorSelect, setVendorSelect } =
    useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser.role == UserRole.Agent) {
      setVendorSelect(null);
    }
  }, []);
  return (
    <>
      <div className="search_container">
        <Select
          options={
            currentUser.role == UserRole.Agent
              ? tamataVendors.map((e) => ({
                  label: e.arabicName || e.id,
                  value: e.id,
                }))
              : [...new Set(tamataVendors.map((e) => e.car))].map((e) => ({
                  label: e,
                  value: e,
                }))
          }
          value={vendorSelect}
          onChange={(val) => {
            setVendorSelect(val);
          }}
          isClearable={true}
          placeholder="Search for vendor"
          styles={vendor_list_style()}
        />
      </div>
      <div className="title">
        <span>Popular Search results</span>
      </div>
      <div className="vendor_list">
        {tamataVendors
          .filter((e) =>
            vendorSelect ? [e.id, e.car].includes(vendorSelect.label) : e
          )
          .map((e, index) => (
            <VendorCard data={e} key={index} index={index} />
          ))}
      </div>
    </>
  );
};
