import dayjs from "dayjs";
import {
  CompleteStatus,
  FailedStatus,
  UpComming,
} from "../../../Components/OrderStatus";
import tookanStatus from "../../../Data/tookanStatus.json";
import CancelStatus from "../../../Data/CancelStatus.json";
import {IoIosAlert, IoLogoWhatsapp} from "react-icons/io";
import {getHours, addHours, AbuseOrder} from "../../../Helper/HelperFun";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../Components/Context";
import {IoAlertCircle} from "react-icons/io5";
import {CustomerSmsModal} from "./CustomerSmsModal";
export const OrderTable = ({orders}) => {
  const {currentUser} = useContext(AuthContext);
  const [isActive, setActive] = useState([]);
  const [orderSelect, setOrderSelect] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    if (!orders) return;
    setActive([...orders.map((e) => ({order_id: e.order_id, show: false}))]);
  }, [orders]);
  return (
    <>
      <div className="table_container">
        <div className="thead">
          <span >#</span>
          <span>Order Id</span>
          <span >Item Name</span>
          <span>Order Create At</span>
          <span>Order Confirm At</span>
          <span>Upload Delivery At</span>
          <span>Compelete At</span>
          <span>status</span>
          <span>Comment</span>
          <span></span>
        </div>
        <div className="row_odd">
          {orders.length !== 0 ? (
            orders.map((e, index) => (
              <div
                className="body_container"
                key={index * 4}
                tabIndex="0"
                onBlur={() =>
                  setActive((prev) => [
                    ...prev.map((e) => ({...e, show: false})),
                  ])
                }
              >
                <span >{index + 1}</span>
                <span>{e.sub_order}</span>
                <span className="small_cell">{e.name}</span>
                <span>
                  {dayjs(addHours(e.created_at, 3)).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </span>
                <span>
                  {dayjs(addHours(e.confirmation_date, 3)).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </span>
                <span>
                  {e.tokan &&
                    dayjs(e.tokan?.creation_datetime).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                </span>
                <span>
                  {(e.tokan?.job_status == 2 || e.tokan?.job_status == 3) &&
                    dayjs(e.tokan?.completed_datetime).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                </span>
                <span style={{display: "flex", justifyContent: "center"}}>
                  {e.tokan ? (
                    CancelStatus.includes(tookanStatus[e.tokan?.job_status]) ? (
                      <FailedStatus text={tookanStatus[e.tokan?.job_status]} />
                    ) : tookanStatus[e.tokan?.job_status].includes(
                      "Successful"
                    ) ? (
                      <CompleteStatus
                        text={tookanStatus[e.tokan?.job_status]}
                      />
                    ) : (
                      <UpComming text={tookanStatus[e.tokan?.job_status]} />
                    )
                  ) : CancelStatus.includes(e.status_m) ? (
                    <FailedStatus text={e.status_m} />
                  ) : e.status_m.includes("complete") ? (
                    <CompleteStatus text={e.status_m} />
                  ) : (
                    <UpComming text={e.status_m} />
                  )}
                </span>
                <span
                  style={{
                    textAlign: "center",
                    direction: "rtl",
                    width: "100% !imortant",
                    display: "inline-block",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <span className="alert">
                    {AbuseOrder(
                      e,
                      currentUser.start_hour,
                      currentUser.finish_hour,
                      currentUser.late_hour
                    ) && <IoIosAlert size={"10px"} />}
                  </span> */}
                  <span>
                    {e.status_m == "canceled"
                      ? "ملغي"
                      : e.tokan?.job_status == 3 && "مؤجل"}
                  </span>
                  {/* <span>
                    {(getHours(addHours(e.created_at, 3)) <
                      currentUser.start_hour ||
                      getHours(addHours(e.created_at, 3)) >=
                      currentUser.finish_hour) && (
                        <div className="popup" tabIndex="0">
                          <IoAlertCircle
                            size={"15px"}
                            onClick={() => {
                              setActive((prevState) => {
                                prevState[
                                  prevState.findIndex(
                                    (c) => e.order_id == c.order_id
                                  )
                                ].show = true;
                                return [...prevState];
                              });
                            }}
                          />
                          <p
                            className={` ${isActive.filter((r) => r.order_id == e.order_id)[0]
                                ?.show
                                ? "show"
                                : ""
                              } popuptext `}
                            id="myPopup"
                          >
                            {`Order After ${currentUser.finish_hour - 12} pm`}
                          </p>
                        </div>
                      )}
                  </span> */}
                </span>
                <span
                  className="small_cell"
                  onClick={() => {
                    setIsOpen(true);
                    setOrderSelect(e)

                  }}
                >
                  <IoLogoWhatsapp fill="green" size={"20px"} style={{cursor: "pointer"}} />

                </span>
              </div>
            ))
          ) : (
            <div>NO Orders</div>
          )}
        </div>
        {
          isOpen && <CustomerSmsModal isOpen={isOpen} setIsOpen={setIsOpen} order={orderSelect} />
        }
      </div>
    </>
  );
};
