import dayjs from "dayjs";
import React from "react";
import { useBarcode } from "react-barcodes";
import  "./index.css"
import {CurrencyIqFormat} from "../../Input/CurrencyIqFormat ";

export const OrderReceipt=({ task, id,note })=>{
    const { inputRef } = useBarcode({
        value: task.order_id,
        options: {
          background: "white",
          height: 50,
    
        },
      });
      console.log(task)
      return (
        <>
        
        <div className="" id={id}>
        <div className="barcode_container">
          <img      src="/Images/tamata_logo.png"
          alt="image_logo"
          width={"30%"} />
          <div>Date :{dayjs(new Date()).format("YYYY-MM-DD")}</div>
        </div>
       
        <div className="barCode">
          <svg ref={inputRef} />
        </div>
        <div className="title_header">
          <div className="title">Number :{task.sub_order.split("-")[1]}</div>
          <div className="title">Vendor  :{task.vendorName}</div>
        </div>
        <div>
          <div className="info_header">
            <div> CUSTOMER INFORMATION</div>
            <div>VENDOR  INFORMATION</div>
          </div>
          <div className="info_field">
            <div className="Location">
              CUSTOMER :  <span className="arabic" > {task.fullName}</span>
            </div>
          </div>
          <div className="info_field">
            <div >LOCATION :  <span className="arabic" lang="ar">{task.address}</span></div>
          </div>
          <div className="info_field">
            <div >
            CUSTOMER   PHONE : {task.phone}
            </div>
          </div>
          <div className="info_field">
            <div >
            Item :{task.name}
            </div>
          </div>
          <div className="info_field">
            <div >
              CITY :<span className="arabic">{task.city}</span>
            </div>
            <div>QTY :{task.total_qty_ordered} </div>
          </div>
          <div className="info_field">
            <div>PRICE : <CurrencyIqFormat val={task.base_grand_total-task.base_shipping_amount}/></div>
            <div>DELIVERYPRICE : <CurrencyIqFormat val={task.base_shipping_amount}/></div>
          </div>
          <div className="info_field">
            <div>TOTAL : <CurrencyIqFormat val={task.base_grand_total}/></div>
        
          </div>
          <div className="info_field" >
            <div style={{backgroundColor:"white",color:"#000"}}>
              NOTE:<span className="arabic note">{note}</span>
            </div>
          </div>
  
        </div>
      <div className="html2pdf__page-break"></div>
      </div>

      </>

    
     
  );
}