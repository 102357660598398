import moment from "moment";
export const getHours = (date) => moment(date).hours();
export const setTime = (date,h=0,m=0,s=0,mm=0) => moment(date).set(h,m,s,mm);
export const addHours = (date, horus = 0) =>
 new Date(
        new Date().setTime(new Date(date).getTime() + horus * 60 * 60 * 1000)
      )

export const getDate = (date) => new Date(date).getDate();

export const DateDiff = (d1, d2) => {
  const diffTime = Math.abs(moment(d2).valueOf() - new Date(d1).valueOf());
  return Math.ceil(diffTime/1000/3600);
};

export const AbuseOrder = (e, start, end, thershold) =>    e.tokan &&
    start < getHours(addHours(e.confirmation_date, 3)) &&
    getHours(addHours(e.confirmation_date, 3)) < end &&
    DateDiff(addHours(e.confirmation_date, 3), e.tokan?.completed_datetime) > thershold
export const timeago=(date)=>{
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}
export const  insidePolygan=(point, vs)=> {

  
  var x = point[0], y = point[1];
  
  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0], yi = vs[i][1];
      var xj = vs[j][0], yj = vs[j][1];
      
      var intersect = ((yi > y) != (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
  }
  
  return inside;
};