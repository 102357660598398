import { NavBar } from "./NavBar";
import { OrderTable } from "./OrderTable";
import { useCallback, useContext, useEffect, useState } from "react";
import { ReqOrdersTypes } from "../../../MiddleWare/ReqOrdersType";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { AuthContext } from "../../../Components/Context";
import { AbuseOrder } from "../../../Helper/HelperFun";
import Popup from "reactjs-popup";
export const Home = () => {
  const { setLoading, currentUser } = useContext(AuthContext);
  const [search, setSearch] = useState("");
  const [orderStatus, setOrderStatus] = useState(null);
  const [orders, setOrders] = useState([]);
  const [lastPage, setLastPage] = useState(false);

  const [page, setPage] = useState({
    count: 0,
    type: "",
  });
  const [lastPageSize, setLastPageSize] = useState(0);
  const fetchDate = useCallback(async () => {
    if (page.type == "prev") {
      setOrders((prev) => [...prev.slice(0, -lastPageSize)]);
      return;
    }
    setLoading(true);
    let response = await ReqOrdersTypes(
      currentUser?.vendorId,
      false,
      page.count
    );
    if (response.length == 0) {
      setLastPage(true);
      setLoading(false);
      return;
    }
    setLastPageSize(response.length);
    setOrders((prev) =>
      [...prev, ...response].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      )
    );
    setLoading(false);
  }, [page]);
  useEffect(() => {
    fetchDate();
  }, [fetchDate]);
  const handelPrev = () => {
    setPage((prev) => ({
      count: prev.count - 1,
      type: "prev",
    }));
  };


  let data = orders;
  return (
    <>
      <div style={{width:"100%"}}>
        <NavBar
          setSearch={setSearch}
          search={search}
          setOrderStatus={setOrderStatus}
          orderStatus={orderStatus}
        />
        <OrderTable orders={data} search={search} />
        <div className="pagintation">
          <div
            className={`${orders.length <= lastPageSize ? "disable" : ""}`}
            onClick={() => {
              orders.length > lastPageSize && handelPrev();
            }}
          >
            <span style={{ marginRight: "5px" }}>
              <AiOutlineArrowLeft size={"15px"} />
            </span>
            <span>Previous</span>
          </div>
          <div
            className={`${lastPage ? "disable" : ""}`}
            onClick={() =>
              !lastPage &&
              setPage((prev) => ({
                count: prev.count + 1,
                type: "next",
              }))
            }
          >
            <span>Next </span>
            <span style={{ marginLeft: "5px" }}>
              <AiOutlineArrowRight size={"15px"} />
            </span>
          </div>
        </div>{" "}
      </div>
    </>
  );
};
