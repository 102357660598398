import dayjs from "dayjs";
import {UserRole} from "../../../Data/UsersRole";
import {
  AgentsCollections,
  vendorsCollections,
} from "../../../Firebase/Collections";
import {
  addDataIntoCache,
  getDataInCache,
} from "../../../MiddleWare/CacheHelper";
import {
  getAllDoc,
  getMultiDoc,
  getSingleDoc,
  updateDocument,
} from "../../../MiddleWare/Firebase-functions";

export const FetchAgentVendors = async (user, isRefresh) => {
  let ActiveVendor = await getSingleDoc(AgentsCollections, "CacheVendors");
  console.log("done")
  addDataIntoCache("fetchAt",dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss"))

  var vendors;
  if (
    getDataInCache("agentsVendors").length == 0 ||
    isRefresh
  ) {
    if (user.role == UserRole.Agent) {
      vendors = await getMultiDoc(vendorsCollections, user.carId, "car","==");
    }else{
      vendors = await getAllDoc(vendorsCollections);
    }

    addDataIntoCache(
      "agentsVendors",
      vendors
        .filter((e) => Object.keys(ActiveVendor).includes(e.name))
        .map((e) => ({ ...e, label: e.name, value: e.name,isFirstTime:ActiveVendor[e.name].isFirstTime }))
    );
    return [
      ... vendors
      .filter((e) => Object.keys(ActiveVendor).includes(e.name))
      .map((e) => ({ ...e, label: e.name, value: e.name,isFirstTime:ActiveVendor[e.name].isFirstTime }))
    ];
  }
  return getDataInCache("agentsVendors");
};
