import { NavBar } from "./Navbar";
import { useCallback, useContext, useEffect, useState } from "react";
import { VendorsList } from "./VendorsList";
import { Route, Routes } from "react-router";
import { CardsList } from "./CardsList";
import { AuthContext } from "../../../Components/Context/index";

import { FetchAgentVendors } from "./FetchAgentVendors";


export const VendorPickUp = () => {
  const { setLoading, currentUser } = useContext(AuthContext);
  const [tamataVendors, setTamataVendors] = useState([]);

  const fetVendorOrders = useCallback(async (isRefresh = false) => {

    setLoading(true);
    var vendorsResponse = await FetchAgentVendors(currentUser,isRefresh);
    setTamataVendors(vendorsResponse);

    setLoading(false);
  }, []);

  useEffect(() => {
    fetVendorOrders();
  }, [fetVendorOrders]);
  return (
    <>
      <div className="vendorPick">
        <NavBar fetVendorOrders={fetVendorOrders} />
        <div className="vendor_pick_contaienr">
          <Routes>
            <Route
              path=""
              element={<VendorsList tamataVendors={tamataVendors} />}
            />
            <Route
              path="/OrdersPick/:id"
              element={<CardsList setTamataVendors={setTamataVendors} />}
            />
          </Routes>
        </div>
      </div>
    </>
  );
};
