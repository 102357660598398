import dayjs from "dayjs";
import React from "react";
import { useBarcode } from "react-barcodes";

export const ItemBarcode=({ id})=>{
    const { inputRef } = useBarcode({
        value: id,
        options: {
          background: "white",
          height: 50,
    
        },
      });
      return (
        <>
        
        <div className="" id={id}>
   
       
        <div className="barCode">
          <svg ref={inputRef} />
        </div>
 
      <div className="html2pdf__page-break"></div>
      </div>

      </>

    
     
  );
}