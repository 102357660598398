import { useContext, useEffect, useState } from "react";
import Button from "./Components/Button/Button";
import Input from "./Components/Input/Input";
import { useLocation, useNavigate } from "react-router";
import { auth } from "./Firebase/Config";
import { AuthContext } from "./Components/Context/index";
import { toast } from "react-toastify";
import { usersCollection } from "./Firebase/Collections";
import { getSingleDoc } from "./MiddleWare/Firebase-functions";

import "./styles/loginStylee.scss";
export const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setLoading, currentUser } = useContext(AuthContext);
  const handelLogin = async (e) => {
    e.preventDefault();
    if (!password || !email) return;
    setLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        getSingleDoc(usersCollection, userCredential.user.uid).then((e) => {
          if (e.role.includes("vendor")) {
            setLoading(false);
            navigate("/");
            return;
          } else if (e.role.includes("agent")) {
            navigate("/Tamata_opration/vendorPickups");
            setLoading(false);

            return;
          }

          navigate("/Tamata_opration");
          setLoading(false);
          return;
        });
      })
      .catch((error) => {
        toast.error("Invalid Login");
        setLoading(false);
      });
  };
  // useEffect(() => {
  //   if (currentUser) navigate(-1);
  // }, []);
  return (
    <>
      {!currentUser && (
        <div className="login-container">
          <div className="login-left-side">
            <h1 className="login-headding">Login</h1>
            <p className="login-text">
              Start using the app and track your order
            </p>
            <div className="login-form" id="login-from">
              <form>
                <Input
                  id="login-form-email"
                  style="input"
                  placeholder="Email"
                  type="email"
                  value={email}
                  onchange={(e) => setEmail(e)}
                ></Input>
                <Input
                  id="login-form-password"
                  style="input"
                  placeholder="Password"
                  type="password"
                  onchange={(e) => setPassword(e)}
                  autocomplete={"on"}
                  value={password}
                ></Input>

                <Button
                  color="ceb0028"
                  style="button"
                  id="login-form-submit"
                  title="Login"
                  onclick={(e) => handelLogin(e)}
                ></Button>
              </form>
            </div>
          </div>
          <div className="logo">
            <img width="300" alt="logo" src="/Images/AR_logo.png" />
          </div>
        </div>
      )}
    </>
  );
};
