import React, { useContext, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "../../../../Components/Button/Button";
import { DialogTitle } from "@mui/material";

export const ConfirmPick = ({ open, setOpen, handelConfirmPick}) => {
  const handleClose = () => {
    setOpen(false);
    
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Are You Sure</DialogTitle>
      <div>
        <span style={{margin:"0px 20px"}}>
          <Button style="Decline" title="Cancle" onclick={()=>handleClose()}/>
          <Button style="Confirm" title="Confirm" onclick={()=>handelConfirmPick()}/>
        </span>
      </div>
    </Dialog>
  );
};
