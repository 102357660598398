import { Routes, Route } from "react-router";
import {  AuthProvider } from "./Components/Context";
import { Spinner } from "./Components/Spinner";
import { Toast } from "./Components/Toast";
import { Login } from "./Login";
import { Home } from "./Pages/Home";
import {queryClient} from "./Config/react-query";
import {QueryClientProvider} from "@tanstack/react-query";

function App() {




  return (
    <div className="container">

          <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Toast />
        <Spinner />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Home/>}/>
        </Routes>
      </AuthProvider>
</QueryClientProvider>
    </div>
  );
}

export default App;
