import { useContext, useEffect, useState } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { toast } from "react-toastify";
import Button from "../../../Components/Button/Button";
import { AuthContext } from "../../../Components/Context";
import {ExportExcel} from "../../../Components/ExportExcel";
import Input from "../../../Components/Input/Input";
import { vendorsCollections } from "../../../Firebase/Collections";
import {
  deleteDocument,
  getAllDoc,
  updateDocument,
} from "../../../MiddleWare/Firebase-functions";
import { FetchMagentoVendors } from "../../../MiddleWare/MagneoVendors";
import { AddVendor } from "./AddVendor";

export const ManangeVendors = () => {
  const [allVendors, setAllVendors] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { setLoading } = useContext(AuthContext);
  const[search,setSearch]=useState("")

  const FetchVendors = async () => {
    let localVendors = await getAllDoc(vendorsCollections);
    localVendors = localVendors.map((e) => ({ ...e, isEdit: false }));
    setAllVendors(localVendors.sort((a, b) => a.name - b.name));
  };
  useEffect(() => {
    FetchVendors();
  }, []);
  const handelSave = async () => {
    setLoading(true)

    const unresolved = allVendors
      .filter((e) => e.isEdit)
      .map(async (e) => {
        await updateDocument(vendorsCollections, e.id, {
          car: e.car.toString(),
        });
      });
    setAllVendors((prev) => [...prev.map((e) => ({ ...e, isEdit: false }))]);
    const resolved = await Promise.all(unresolved);
    setLoading(false)

    toast.success("تم التحديث");

    setIsEdit((r) => !r);
  };
  const deleteVendors = async (e) => {
    if(window.confirm("هل انت متاكد")){
      setLoading(true)
      await deleteDocument(vendorsCollections, e.id);
      setAllVendors((prev) => {
        prev = prev.filter((r) => r.name !== e.name);
        return [...prev];
      });
      setLoading(false)
      toast.success("تم الحذف");
    }
   
  };
  console.log(allVendors
    .filter((e) => e.isEdit))
  return (
    <>
     <div className="container">
          <div className="navbar-wrapper">
            <div className="logo-search-wrapper">
              <div className="logo" style={{ marginRight: "10px" }}>
                <img width="80px" alt="logo" src="/Images/tamata_logo.svg" />
              </div>
          
              <Input
                id="search"
                style="input search"
                placeholder="Searching.... "
                type="text"
                value={search}
                onchange={(e) => setSearch(e)}
              />
              <div className="nav_list">
                <span></span>
              </div>
            </div>
          </div>
        </div>
      <div className="table_container">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Button
            color="c70b44d"
            style="button c70b44d"
            id="log-out"
            onclick={() => {
              if (isEdit) {
                handelSave();
                return;
              }
              setIsEdit(true);
            }}
            title={`${isEdit ? "Save All" : "Edit"}`}
          />
        
          <Button
            color="C2578AF"
            style="button C2578AF"
            title="Add Vendor"
            onclick={() => setIsOpen((e) => !e)}
          />
            <Button
            color="c70b44d"
            style="button c70b44d"
            id="log-out"
            onclick={() => ExportExcel(allVendors,"Tamata Vendor")}
            title={`Export Excel`}
          />  
        </div>
        <div className="thead">
          <span className="small_cell">#</span>
          <span className="small_cell">Name</span>
          <span className="small_cell">Car</span>
          <span className="small_cell"></span>
        </div>
        <div className="row_odd">
          {allVendors.length !== 0 ? (
            allVendors
              .filter((e) => e.id.toLowerCase().includes(search.toLowerCase()))
              .map((e, index) => (
                <div
                  className="body_container"
                  key={index * 4}
                  style={{
                    height: "5%",
                    margin: "10px 2px",
                    borderRadius: "2px",
                  }}
                >
                  <span className="small_cell">{index + 1}</span>
                  <span className="small_cell">{e.name}</span>

                  <span className="small_cell">
                    <input
                      disabled={!isEdit}
                      onBlur={(d) => {
                        setAllVendors((prev) => {
                          prev[
                            prev.findIndex((r) => r.name == e.name)
                          ].isEdit = true;

                          return [...prev];
                        });
                      }}
                      onChange={(d) => {
                        setAllVendors((prev) => {
                          prev[prev.findIndex((r) => r.name == e.name)].car =
                            d.target.value;

                          return [...prev];
                        });
                      }}
                      style={{
                        border: `${isEdit ? "1px solid #efefef" : "none"}`,
                        textAlign: "center",
                      }}
                      value={e.car || 0}
                    />
                  </span>

                  <span className="small_cell" onClick={() => deleteVendors(e)}>
                    <AiFillDelete fill="#D61C4E" />
                  </span>
                </div>
              ))
          ) : (
            <div>NO Vendors</div>
          )}
        </div>
        {isOpen && <AddVendor isOpen={isOpen} setIsOpen={setIsOpen} />}
      </div>
    </>
  );
};
